import React from 'react';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Education() {
    return <Paper elevation={3} className='section-container' id='education'>
        <h2>Education</h2>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-psu-content"
                id="panel-psu-header"
            >
                <Typography><b>Penn State Harrisburg</b> <i>2005 - 2009 (Computer Science)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    While I was here, along with my normal studies, I ran the electrical engineering computer lab and was their Windows server administrator.
                    I made a hard attempt at creating a simulation programming club. I spent much of my time learning new concepts and testing project ideas.
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-digi-content"
                id="panel-digi-header"
            >
                <Typography><b>DigiPen Institute of Technology</b> <i>2003 - 2005 (Realtime-Interactive-Simulation-Programming)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    DigiPen focused on simulation programming and real-time interactive simulations in general.
                    It was very intense; a semester often required more than 21 credits (this isn't good!).
                    I was put on teams of highly dedicated programmers to create some quality video game concepts
                    as well as understand core underlying concepts. I leveraged the experience of my professors
                    and really learned a lot of what I put into practice today.
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-bs-content"
                id="panel-bs-header"
            >
                <Typography><b>Big Spring High School</b> <i>Graduated 2003</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Along with my normal classes I made sure to take every math and physics class I could.
                    I fed my hobby for programming as much as possible and went to Lock Haven College for a Linux programming camp my junior year as well.
                    I was an active member in every musical program I could find, including a competitive marching band and dedicated percussion group.
                </Typography>
            </AccordionDetails>
        </Accordion>
    </Paper>;
}
