import React from 'react';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { indigo, pink, teal, purple } from '@mui/material/colors';

export default function Summary() {
    return <Paper elevation={3} className='section-container' id='skills'>
        <h2>Skills</h2>
        <Stack id='skills-stack' direction="row" spacing={1} style={{
            flexWrap: 'wrap',
            width: '100%',
        }}>
            <Chip label={'TypeScript (~7+ yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'ECMAScript (10+ yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'C++ (~10 yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'C# (~3 yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'Rust (~1+ yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'Python (~4 yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'PHP (~4 yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'SQL (10+ yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'WebGL (~3+ yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'OpenGL (~10 yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'Vulkan (~1 yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'GLSL (~10+ yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'CMake (~8 yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'Flutter (~1 yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'WebPack (~7+ yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'React (~2+ yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'Redux (~3+ yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'Jotai (~1+ yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'Zustand (~1+ yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'Mui (~1+ yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'LitElement (~3+ yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'Sass (~6+ yrs)'} style={{ backgroundColor: pink[900] }} />
            <Chip label={'CSS (~10+ yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'HTML5 (~8+ yrs)'} style={{ backgroundColor: indigo[900] }} />
            <Chip label={'MySQL (~10+ yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'PostgreSQL (~4+ yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'AWS (~4+ yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'S3 (~4+ yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'DynamoDB (~2+ yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'RDS (~3+ yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'Redis (~10+ yrs)'} style={{ backgroundColor: teal[900] }} />
            <Chip label={'Linux (Preferred)'} style={{ backgroundColor: purple[900] }} />
            <Chip label={'Android'} style={{ backgroundColor: purple[900] }} />
            <Chip label={'MacOS'} style={{ backgroundColor: purple[900] }} />
            <Chip label={'Windows'} style={{ backgroundColor: purple[900] }} />
        </Stack>
    </Paper>;
}
