import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Link from '@mui/material/Link';

const pages = ['Skills', 'Experience', 'Projects', 'Education'];

const Header = () => {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar src='https://avatars.githubusercontent.com/u/240517?v=4' style={{marginRight: '16px'}} />
          <h3>Douglas E. Reisinger II</h3>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link
                className='nav-link'
                key={page}
                underline='none'
                href={`#${page.toLowerCase()}`}
              >
                {page}
              </Link>
            ))}
          </Box>
          <Box className='resource-links'>
              <Link underline='none' target='none' href='https://github.com/palodequeso'><GitHubIcon /></Link>
              <Link underline='none' target='none' href='https://www.linkedin.com/in/douglas-e-reisinger-ii-a4060483/'><LinkedInIcon /></Link>
              <Link underline='none' target='none' href='mailto:palodequeso@gmail.com'><EmailIcon /></Link>
              <Link underline='none' target='none' href='tel:+17173647434'><PhoneIcon /></Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
