import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from '@mui/material/Modal/Modal';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    p: 4,
};

export function ProjectImageList(props) {
    const { images } = props;
    const [ modalImage, setModalImage ] = useState(null);

    const imgModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        verticalAlign: 'middle' as 'middle',
        objectFit: 'contain' as 'contain',
    };

    return <ImageList
        variant='quilted'
    >
        {Object.keys(images).map(key => <ImageListItem key={`img-${key}`} style={{cursor: 'pointer'}}>
            <img src={`../../../${images[key]}`} alt={key} loading='lazy' onClick={() => { setModalImage(images[key]); }} />
            <Modal
                open={modalImage !== null}
                onClose={() => setModalImage(null)}
            >
                <Box sx={modalStyle}>
                    <img src={`../../../${modalImage}`} alt={key} loading='lazy' style={imgModalStyle} />
                   <Fab
                        onClick={() => setModalImage(null)}
                        style={{position: 'absolute', top: '24px', right: '24px'}}
                    >
                        <CloseIcon />
                    </Fab> 
                </Box>
            </Modal>
        </ImageListItem>)}
    </ImageList>;
}

export default function Projects() {
    return <Paper elevation={3} className='section-container' id='projects'>
        <h2>Projects</h2>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography><b>Budgie</b> <i>Eventually Open-sourced zero-based-budgeting app</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    A zero-based budgeting app designed for all us normies and not the rich. It's a work in progress and but progress is made steadily.
                </Typography>
                <ProjectImageList images={ {
                    'budgie-complex': 'budgie-complex.png',
                    'budgie-budget': 'budgie-budget.png',
                    'budgie-calendar': 'budgie-calendar.png',
                    'budgie-mark': 'budgie-mark.png',
                } } />
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-wwda-content"
                id="panel-wwda-header"
            >
                <Typography><b>Well, We're Doomed Anyway</b> <i>(2D Game, Custom Engine, TypeScript, WebGL)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>Starting with just the goal of using a strategy game as a way of learning more TypeScript and optimizing WebGL rendering, I ended up making most of a game, so I’m trying to finish this now. The repo is private, but if you send me a GitHub login, I’ll gladly share the code. It isn’t tested well as it’s just a side project, but I’m happy with how it’s going. The renderer does basic scene sorting/graphing because I actually had some slowdowns with the graphics layer, especially with particle systems, etc.</Typography>
                <Typography>Update: Ended up just rebuilding for the web and I plan to iterate publically there. <Link target="_blank" href="https://shark-app-ez8vl.ondigitalocean.app/">Game</Link></Typography>
                <ProjectImageList images={ {
                    'wwda-title': 'wwda-title.png',
                    'wwda-camp': 'wwda-camp.png',
                    'wwda-battle': 'wwda-battle.png',
                } } />
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-inhumanus-content"
                id="panel-inhumanus-header"
            >
                <Typography><b>Inhumanus</b> <i>(VR Game, SteamVR, Unity)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div><Link href='https://store.steampowered.com/app/767970/Inhumanus/' target='none'>Steam</Link></div>
                <Typography>With another friend, we made a VR boomer-shooter with coop. Using Unity and SteamVR I implemented all of the AI, gun-handling, a few levels, and other random bits. It’s not a great game, but it was a fun project, and my first full side-game release.</Typography>
                <ProjectImageList images={ {
                    'inhum-title': 'inhum-title.jpg',
                    'inhum-menu': 'inhum-menu.jpg',
                    'inhum-bh': 'inhum-bh.jpg',
                    'inhum-bot': 'inhum-bot.jpg',
                    'inhum-ship': 'inhum-ship.jpg',
                    'inhum-vents': 'inhum-vents.jpg',
                } } />
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-planets-content"
                id="panel-planets-header"
            >
                <Typography><b>Past Game Engine and Planet Generator Work</b> <i>(3D, Custom Engine, GLSL, Procedural Generation)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    I spent quite a few years using the excuse of making my own C++/OpenGL game engine, capable of generating planets and doing deferred rendering, to learn a whole slew of things. Here's some screenshots!
                    Sometimes I contribute to other projects, such as when this gfx lib in Rust was started, I made a deferred demo for it. It has since been deprecated and taken over, but I loved doing it, and things like it. <Link href='https://github.com/glium/glium/blob/master/examples/deferred.rs' target='_blank'>Github Source</Link>
                </Typography>
                <ProjectImageList images={ {
                    'planet1': 'planet1.jpg',
                    'planet2': 'planet2.jpg',
                    'planet3': 'planet3.jpg',
                    'planet5': 'planet5.jpg',
                    'deferred-cube': 'deferred-cube.jpg',
                    'deferred-jet': 'deferred-jet-bloom-shadows.jpg',
                }} />
            </AccordionDetails>
        </Accordion>
    </Paper>;
}
