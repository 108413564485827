import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Education from './components/education';
import Experience from './components/experience';
import Header from './components/header';
import Projects from './components/projects';
import Summary from './components/summary';
import './index.scss';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function handleScroll() {
    const scrollTop = document?.body?.scrollTop === 0 ? document?.documentElement?.scrollTop : document?.body?.scrollTop;
    const btn = (document.querySelector('.back-to-top') as HTMLElement);
    if (!btn) {
      return;
    }
    btn.style.display = scrollTop === 0 ? 'none' : 'flex';
}

document.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('scroll', handleScroll);
    ReactDOM.render(
        <ThemeProvider theme={darkTheme}>
            <Container maxWidth="lg">
              <a href='#top' />
              <Header />
              <Summary />
              <Experience />
              <Projects />
              <Education />
              <Fab className='back-to-top' variant="extended" onClick={() => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
              }}>
                <KeyboardArrowUpIcon />
                Back To Top
              </Fab>
            </Container>
        </ThemeProvider>,
        document.getElementById('react-container'),
    );
    handleScroll();
});
