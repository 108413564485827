import React from 'react';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Experience() {
    return <Paper elevation={3} className='section-container' id='experience'>
        <h2>Experience</h2>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-aws-content"
                id="panel-babyscripts-header"
            >
                <Typography><b>Babyscripts</b> <i>(Tech Lead, Dec 2022 - Present)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>Working at Babyscripts has allowed me to fulfill a life-long dream of helping others with my code. We directly impact the lives of pregnant people with preeclampsia and other pregnancy-related conditions.</Typography>
                <h4>Notable Projects</h4>
                <ul>
                    <li>New app project written in Flutter along with a new application API that serves as a solid gateway to the rest of the platform.</li>
                    <li>Brought a long-running project over the finish line. The project allows users to take health assessments assigned by their providers to help catch postpartum depression earlier. These assessments can also direct patients to resources that may aid in pregnancy-related issues, including loss or birth complications.</li>
                </ul>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-bibliu-content"
                id="panel-bibliu-header"
            >
                <Typography><b>BibliU</b> <i>(Senior Software Engineer, 2019 - Dec 2022)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>During my time at BibliU I’ve worked on several different student and university focused projects.</Typography>
                <h4>Notable Projects</h4>
                <ul>
                    <li>Universal-Learning: The evolution from the student team below, this team is focused on integrating more universities and helping solve technology challenges to meet their requirements. On this team, I’m using a data pipeline of AWS/S3 or SQL -&gt; Stitch -&gt; Snowflake -&gt; DBT -&gt; Retool/PopSQL. With this pipeline, we can automatically consume enrollment data as well as book info uploads from any university, whether it be from their DB directly, or CSV upload.</li>
                    <li>Student: The student team was my main home while at BibliU, and the focus of the team was delivering app experience improvements to the students, and evaluating their feedback. While on this team we engineered solutions such as full book TTS, improvements for color blindness, app speed improvements, and a UI/A11y overhaul in general with Lit Element.</li>
                    <li>Core takeaways from my years at BibliU have been better project management, cross-world communication and scheduling work, AWS as a whole, TypeScript in earnest, and modern data pipelining. I’ve also taken this time to explore a ton of React and front-end data management.</li>
                </ul>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-wwda-content"
                id="panel-wwda-header"
            >
                <Typography><b>Tex Visions</b> <i>(Senior Software Developer, 2014 - Oct 2019)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>When I joined the team, they were in dire need for more software developers. I quickly stepped in and started fixing bugs that were long overdue. I then started work on some long overdue projects to help the company further their reach into the world of E-Commerce. Also, since I am the Senior Developer, I spend a lot of time mentoring people on best practice, and performing code reviews. With my boss’s persuasion, I’ve implemented daily stand ups, and a better bug tracking system, using waffle.io.</Typography>
                <h4>Notable Projects</h4>
                <ul>
                    <li>Engineered a Product Feed System which generates pricing data for various product configurations and dispatches the information about said products to various shopping aggregators. These sites then list our product data on their site to help generate more sales leads. It interfaces with both the CSCart shopping cart system as well as Microsoft AX, and finally generates data to be sent automatically to the feed, as well as Excel spreadsheets showing the current generated data set, and another spreadsheet which contains a changelog.</li>
                    <li>Wrote a textile printing design tool with HTML5 which allows users to make their own print designs online without the need for any skills or licenses to any higher end graphic design software such as Photoshop or The GIMP. The backend is C# with DotNet Core.</li>
                    <li>Contributed a significant number of bug fixes to their E-Commerce site, tidying up long overdue loose ends. Most of the work has been in PHP but there is a significant amount of work that has been done on the frontend with Javascript and HTML as well.</li>
                    <li>Created a database abstraction service which sits on top of all of our MySQL databases. It is written in NodeJS using a multi-process architecture. It handles multiple databases, and migrations. It uses the Sequelize ORM to abstract away the models. I also wrote an auto-discovering controller system which uses JSON schema to validate incoming and outgoing data.</li>
                    <li>Wrote the framework, and the majority of the company intranet using NodeJS on the backend, and Backbone.js on the frontend. We use it for everything from help desk tickets to accounting reports.</li>
                    <li>Leveraged the newly created intranet to create a production automation system. It schedules incoming production jobs to ensure they get done in time, and allows production workers to easily keep track of their daily tasks and know the progress on any order.</li>
                    <li>Used C# with DotNet Core to deploy a linux service that serves as both the box packing system and the shipping service. It receives a list of items to be shipped, including fabric that gets rolled and folded, and packs it into boxes while wasting as little volume as possible. It also abstracts away communications between UPS and FedEx to get shipping rates, address validations, etc.</li>
                </ul>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-wwda-content"
                id="panel-wwda-header"
            >
                <Typography><b>VS Networks</b> <i>(Software Engineer, 2011 - 2014)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>At VS Networks, I was tasked with tackling items across the entire stack. I’ve done everything from systems programming and database work, to complicated front-end JavaScript. The company specializes in digital advertising, and produces display and touch machines for showing high quality digital media. It is entirely managed by a full-featured website where users can upload digital media in numerous formats and schedule them for play-time on their various devices.</Typography>
                <h4>Notable Projects</h4>
                <ul>
                    <li>Continual Enhancement of the Web Interface for managing client organization, media scheduling and media uploads. This website includes rich modern web interfaces utilizing numerous libraries including Require, Underscore, Backbone, Moment, Bootstrap, etc.</li>
                    <li>Wrote software with C++ and Qt to distribute/view our touch based catalogs to Windows operating systems, allowing users to take our catalogs with them on any regular business laptop or Windows tablet. I also wrote the first Android version as well.</li>
                    <li>Wrote software with C++ and Qt to display rich HTML/Javascript based ads that can display dynamic information such as weather, commodities or anything that continually updates itself.</li>
                    <li>Designed a system with Python and PostgreSQL to process Ad play logs and touch catalog click logs into a statistics database and APIs to retrieve that information back out for reporting. At its peak the tables in this database had over 100 million rows of data from just a few months.</li>
                    <li>Designed a reporting system with Python and PostgreSQL which aggregates data from numerous sources, including our customers and ads database, as well as the system mentioned above. These reports are crucial for giving short and long term insight into the business and media system usage.</li>
                </ul>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-wwda-content"
                id="panel-wwda-header"
            >
                <Typography><b>Bed Bath &amp; Beyond</b> <i>(Programmer / Analyst, 2010 - 2011)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>I spent a year working closely with a business analyst, putting our statistical analyses and workplace enhancements into practice. We worked hard to improve warehouse processes with software automation, driven by real statistics. We also analyzed other parts of the e-commerce business as a whole to help drive the company forward.</Typography>
                <h4>Notable Projects</h4>
                <ul>
                    <li>I wrote a web interface which sat upon a Windows, Apache, MySQL and Python base, used HTML/Javascript with AJAX, and pulled data from numerous systems to quickly diagnose shipping boxes which had some issue, such as a weight miss-match, boxing issue, addressing issue, and so forth.</li>
                    <li>Worked on an analysis of customer purchase records over 3+ years to determine the location of the next shipping facility. The software that I wrote was able to place one or many facilities based on shipping zone optimizations.</li>
                    <li>I wrote a web interface and reporting backend with Python, HTML and Javascript to automate some of the aging reporting techniques. Previously it would take one employee several days out of the week to complete a single report. We automated many of these and they would run in just a few minutes.</li>
                </ul>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-wwda-content"
                id="panel-wwda-header"
            >
                <Typography><b>Adept Results</b> <i>(Programmer, 2007 - 2010)</i></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>I started at Adept when they had only two programmers and was a small startup. We did E-Commerce websites that required rapid analysis of conversion rates and usage patterns.</Typography>
                <h4>Notable Projects</h4>
                <ul>
                    <li>Manage nightly credit-card processing systems along with secure databases running on MySQL.</li>
                    <li>Created a web-frontend in Perl, HTML and Javascript, using AJAX for live web-site conversion rates. This allowed us to make rapid decisions on which designs worked and which did not. The statistics were refreshed every minute.</li>
                    <li>Helped write a content management system in Perl for rapid e-commerce site development and deployment. It allowed users to insert templatization so a brand new site could be deployed into a working state, without any extra coding.</li>
                </ul>
            </AccordionDetails>
        </Accordion>
    </Paper>;
}
